<template>
  <b-card title="Departament">
    <b-form>
      <validation-observer ref="simpleRules">
        <form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group id="input-group-1" label="Title" label-for="input-title">
                <validation-provider #default="{ errors }" name="Title" rules="required">
                  <b-form-input id="input-title" v-model="form.title" type="text" placeholder="Title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group id="input-group-2" label="Description" label-for="input-description">
                <validation-provider #default="{ errors }" name="Description" rules="required">
                  <b-form-input id="input-description" v-model="form.description" type="text"
                    placeholder="This departament..." />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Owner">
                <validation-provider #default="{ errors }" name="Owner" rules="required">
                  <v-select v-model="form.owner" :options="users" label="name" placeholder="Choose owner" class="w-100"
                    :clearable="false">
                    <template #list-header>
                      <b-button variant="outline-primary" block @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Member">
                <validation-provider #default="{ errors }" name="Member" rules="required">
                  <v-select v-model="form.members" :options="users" label="name" multiple placeholder="Choose member"
                    class="w-100">
                    <template #list-header>
                      <b-button variant="outline-primary" block @click="$refs['createUser'].show()">Create User</b-button>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" v-if="!isMyself">
              <b-form-group id="input-group-3" label="Organization" label-for="input-icon">
                <validation-provider #default="{ errors }" name="Organization" rules="required">
                  <v-select v-model="form.organization" :options="organizations" label="title"
                    placeholder="Choose organization" class="w-100" :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group id="input-group-3" label="Icon" label-for="input-icon">
                <b-form-input id="input-icon" v-model="form.icon" type="text" placeholder="Selecione o Ícone" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                @click="postDepartaments()">
                Guardar
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                @click="$router.push('/departaments')">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-form>

    <b-modal ref="createUser" hide-footer hide-header size="lg" no-close-on-backdrop>
      <UserCreate @close="closeModal" />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
} from 'bootstrap-vue'
import DepartamentController from '@/controllers/DepartamentsController'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import UserCreate from '@/components/atoms/users/UserCreate.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

const { log } = require('@/utils/log')

export default {
  components: {
    UserCreate,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    required,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      form: {
        title: '',
        description: '',
        icon: '',
        members: [],
        owner: '',
        organization: ''
      },
      isMyself: false
    };
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('getAllUsers'),
      this.$store.dispatch('getAllOrganizations')
    ])

    const id = this.$route.params.id;
    this.id = id;
    if (this.$route.name === 'departaments/edit') {
      const response = await this.searchDepartament(id);
      if (response === 404) {
        this.$router.push('/error-404');
      } else {
        this.form.title = response.data.title;
        this.form.description = response.data.description;
        this.form.icon = response.data.icon;
        this.form.owner = response.data.owner;
        this.form.members = response.data.members;
        this.form.organization = response.data.organization;
      }
    }

    if (this.currentUser.roles[0].name === 'HEAD_OF_ORGANIZATION') {
      this.isMyself = true;
      this.form.organization = this.organizations.find((organization) => organization.owner.id === this.currentUser.id)
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.usersConfig.currentUser
    },
    users() {
      return this.$store.state.usersConfig.users ?
        this.$store.state.usersConfig.users
          .filter((user) => user.status === 1) :
        []
    },
    organizations() {
      return this.$store.state.organizationConfig.organizations
    }
  },

  methods: {
    async searchDepartament(id) {
      try {
        const controller = new DepartamentController();
        const response = await controller.getDepartament(id);
        return response;
      } catch (error) {
        log(error);
        return error.response.status;
      }
    },

    async postDepartaments() {
      const isValid = await this.$refs.simpleRules.validate()
      if (isValid) {
        const members = this.form.members.map((member) => member.id)

        const departament = {
          title: this.form.title,
          description: this.form.description,
          icon: this.form.icon,
          fk_owner: this.form.owner ? this.form.owner.id : null,
          members: members,
          fk_organization: this.form.organization.id,
        }

        try {
          const departamentController = new DepartamentController()
          if (this.$route.name != 'departaments/edit') await departamentController.postDepartaments(departament)
          else await departamentController.putDepartaments(this.id, departament)

          this.$router.push('/departaments');
        } catch (error) {
          log(error)
        }
      }
    },

    closeModal() {
      this.$store.dispatch('getAllUsers')
      this.$refs['createUser'].hide()
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
